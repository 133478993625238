<template>
    <div>
        <Banner src="/assets/img/plholder.jpg" title="Festivais"/>
        <br>
        <v-row 
          justify="center">
            <v-col></v-col>
            <v-col cols="9">
                <v-row>
                    <v-col v-for="category in categories" :key="category.id" 
                        cols="4">
                        <Card 
                            :item="category"/>
                    </v-col>
                </v-row>
            </v-col>
            <v-col></v-col>
        </v-row>
        <div class="text-center" v-show="hasMore">
            <br><br>
            <v-btn @click="fetchMore" outlined rounded :loading="loading">
                <font-awesome-icon icon="redo-alt" /> <span> Mostrar Mais</span>
            </v-btn>
        </div>
    </div>
</template>

<script>
import Card from '../Card';
import Banner from '../Banner';


import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data: () => ({}),
    created () {
        this.fetchCategories({ limit: 18, });
    },
    computed: {
        ...mapGetters('festival', [ 'hasMore']),
        ...mapState('festival', ['categories', 'hasMore', 'loading']),
    },
    methods: { 
        ...mapActions('festival', {
            fetchCategories: 'list',
            loadMore: 'loadMore'
        }),
        fetchMore: function () {
            this.loadMore({ limit: 18 })
        },
    },
    components: {
        Card,
        Banner,
    },
    
    
}
</script>