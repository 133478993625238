<template>
    <v-row no-gutters>
        <v-col cols="2">
            <v-row>
                <!--MaskedImg :src="item.src" /-->
                <v-col>
                    <div class="justify-center align-center text-center" >
                        <v-img v-if="item.src" :src="item.src" max-width="150px"/>
                    </div>
                </v-col>
            </v-row>
            <v-row v-if="isLoggedIn">
                <v-col>
                    <v-btn small color="primary" class="black--text elevation-0" 
                     @click="dialog = true">
                        <span class="black--text">Concorrer</span>
                    </v-btn>
                    <AddToFavorite :item="item" code='IN_VIEW' />
                </v-col>
                
            </v-row>
        </v-col>
        <v-col cols="10"  :style="separator">
            <div :class="'ml-4'">
                <br>
                <router-link :to="item.$route" class="decoration-none">
                    <p class="headline text-primary text-center">{{ item.title }}</p>
                </router-link>
                <p v-html="item.description"></p>
                <router-link :to="item.$route" class="decoration-none">
                    <p class="text-primary ">[Saber mais]</p>
                </router-link>
                <div class="text-primary post-info">
                    <slot></slot>
                </div>
                <br>
            </div>
        </v-col>
        <SubscribeDialog
            v-if="item.allowedProjects && item.allowedProjects.length > 0 && !item.registerDateExpired"
            :dialog="dialog"
            :allowedProjects="item.allowedProjects"
            v-on:changeDialog="val => dialog = val"
            :allowedCategories="allowedCategories"
        />
    </v-row>
</template>
<script>
//import MaskedImg from './MaskedImg';
import SubscribeDialog from '../Subscribe';
import AddToFavorite from './AddToFavorite';
export default {
    props: {
        item: Object,
        divider: {
            type: Boolean,
            default: true
        },
    },
    data: () => ({
        dialog: false
    }),
    computed: {
        separator() {
            return this.divider ? {
                'border-bottom-style': 'solid', 
                'border-bottom-width': '2px',
                'border-bottom-color': '#FFCC00'
            } : null;
        },
        isLoggedIn() {
            //return this.$session.get(SESSION_ID) ? true : false;
            if (this.$user.isGuest) {
                return false;
            }
            return true;
        },
        allowedCategories() {
            if (this.item.allowedProjects)
                return this.item.allowedProjects.map(o => o.id)
            return []
        },
    },
    components: {
        //MaskedImg,
        SubscribeDialog,
        AddToFavorite
    }
}
</script>