<template>
    <div>
        <Banner v-if="category" :src="category.src" :title="title"/>
        <br>
        <v-card class="post-body pt-2 pb-1">
                <Filters
                    :name="f_name"
                    :categories="f_categories"
                    :date="f_date"
                    :local="f_local"
                    v-on:setLocal="val => f_local = val"
                    v-on:setName="val => f_name = val"
                    v-on:setDate="val => f_date = val"
                    v-on:setCategories="val => f_categories = val" 
                    @pesquisar="pesquisar"/>
            </v-card>
            <br/>
        <v-container>
            
            

            <v-row class="fill-height" align-content="center" justify="center">
                <v-col class="subtitle-1 text-center" cols="12" v-if="loading">
                    Pesquisando festivais...
                </v-col>
                <v-col cols="6" v-if="loading">
                    <v-progress-linear
                        color="deep-purple accent-4"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

                <v-col cols="12">
                    <v-alert
                        border="top"
                        colored-border
                        :type="festivals.length > 0 ? 'success' : 'error'"
                        elevation="2"
                        v-if="f_name || f_date"
                    >
                        Mostrando resultados para: {{ f_name }} {{ f_date }}
                    </v-alert>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12" v-for="post in festivals" :key="post.id" class="post-body mb-8 cliped-rb-path">
                    <Post :divider="false" :item="post">
                        <!--span>
                            <font-awesome-icon icon="calendar" title="Data limite de Inscrição"/><span> {{ post.startingDate || 'Data por definir'}}</span>
                        </span-->
                    </Post>
                </v-col>
            </v-row>

            <div class="text-center" v-if="hasMore">
                <v-btn @click="fetchMore" outlined rounded :loading="loading">
                    <font-awesome-icon icon="redo-alt" /> <span> Mostrar Mais</span>
                </v-btn>
            </div>
        </v-container>
        
    </div>
</template>

<script>
//import Post from '../Post';
import Post from './FestivalPost';
import Banner from '../Banner';
import Filters from './Filters';

import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    inject: {
      theme: {
        default: { isDark: false },
      },
    },
    data: () => ({
        f_name: null,
        f_local: null,
        f_categories: [],
        f_date: null, //new Date().toISOString().substr(0, 10),
        filter: {}
    }),
    created () {
        Object.assign(this.filter, { limit: 18, category: this.categoryId });
        //this.fetchFestivals(this.filter);
        this.fetchFilter(this.filter);
        this.listSubSubCategory({});
    },

    computed: {
        ...mapGetters('festival/festival', [ 'hasMore', 'error']),
        ...mapState('festival/festival', ['festivals', 'category', 'hasMore', 'loading']),
        title() {
            return `Festivais${this.category ? ' - '+this.category.title : ''}`
        },
        categoryId () {
            return this.$route.query.tipo;
        }
    },
    methods: { 
        ...mapActions('festival/festival', {
            fetchFestivals: 'list',
            fetchFilter: 'listFilter',
            loadMore: 'loadMore'
        }),

        ...mapActions('project', ['listSubSubCategory']),

        fetchMore: function () {
            this.loadMore({ limit: 18, categoryId: this.categoryId })
        },
        pesquisar() {
             Object.assign(this.filter, {
                limit: 18,
                category: this.categoryId,
                name: this.name,
                allowedProj: this.allowedProj,
                date: this.date,
                local: this.f_local
            });
            this.fetchFilter(this.filter);
        }
    },
    components: {
        Post,
        Banner,
        Filters
    },
    
    watch: {
        f_date(val) {
            Object.assign(this.filter, {
                limit: 18,
                category: this.categoryId,
                name: this.name,
                allowedProj: this.allowedProj,
                date: val,
                local: this.local
            });
            this.fetchFilter(this.filter);
        },

        f_name(val) {
            Object.assign(this.filter, {
                limit: 18,
                category: this.categoryId,
                name: val,
                allowedProj: this.allowedProj,
                date: this.date,
                local: this.local
            });
            this.fetchFilter(this.filter);
        },

        f_local(val) {
            Object.assign(this.filter, {
                limit: 18,
                category: this.categoryId,
                name: this.name,
                allowedProj: this.allowedProj,
                date: this.date,
                local: val
            });
            this.fetchFilter(this.filter);
        },

        f_categories(val) {
            Object.assign(this.filter, {
                limit: 18,
                category: this.categoryId,
                name: this.name,
                allowedProj: val,
                date: this.date,
                local: this.local
            });
            this.fetchFilter(this.filter);
        }
    }
}
</script>