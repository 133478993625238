<template>
    <div>
        <Category v-if="isListCategory" />
        <Festivals v-else />
    </div>
</template>

<script>
import Category from '../components/festival/Category';
import Festivals from '../components/festival/Festivals';


import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    data: () => ({}),
    created () {
        this.fetchCategories({ limit: 18, });
    },
    computed: {
        ...mapGetters('festival', [ 'hasMore']),
        ...mapState('festival', ['categories', 'hasMore', 'loading']),
        isListCategory() {
            return !this.$route.query.tipo;
        },
    },
    methods: { 
        ...mapActions('festival', {
            fetchCategories: 'list',
            loadMore: 'loadMore'
        }),
        fetchMore: function () {
            this.loadMore({ limit: 18 })
        },
    },
    components: {
        Category,
        Festivals,
    },
    
    
}
</script>