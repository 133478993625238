<template>
    <div>
        <v-row class="ma-3">
            <v-col cols="12" md="6">
                
                <v-text-field 
                    v-model="_name"
                    :counter="30"
                    label="Procuro Por"
                    required
                    outlined
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
                <!--filtrar por categorias-->
                <p class="text-uppercase">Categoria</p>
                <v-select
                    v-model="_categories"
                    :items="subSubCategories"
                    label=""
                    item-text="title"
                    item-value="id"
                    persistent-hint
                    :loading="loading"
                    chips
                    return-object
                    single-line
                    multiple
                    dense
                    >
                    <template v-slot:item="{ item: {title, description}}">
                        {{title}} ({{description}})
                    </template>    
                </v-select>
            </v-col>

            <v-col cols="12" md="6">
                
                <v-text-field 
                    v-model="_local"
                    :counter="180"
                    label="Em"
                    required
                    outlined
                    dense
                ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
                <v-menu
                    v-model="menu"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                >
                    <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                        v-model="date"
                        label="filtrar por data"
                        prepend-icon="mdi-calendar"
                        readonly
                        outlined
                        dense
                        v-bind="attrs"
                        v-on="on"
                    ></v-text-field>
                    </template>
                    <v-date-picker v-model="_date" @input="menu = false"></v-date-picker>
                </v-menu>
            </v-col>
        </v-row>
        <v-row class="ma-3">
            <v-col>
                <v-btn color="primary" @click="emitPesquisar">
                    <span class="black--text">Pesquisar</span>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
    data: () => ({
        menu: false
    }),

    props: ["name", "local", "date", "categories"],
    
    computed: {
        ...mapState('project', ['subSubCategories', 'loading']),
        _name: {
            get() { return this.name },
            set(val) { this.$emit("setName", val) }
        },

        _local: {
            get() { return this.local },
            set(val) { this.$emit("setLocal", val) }
        },


        _date: {
            get() { return this.date },
            set(val) { this.$emit("setDate", val) }
        },

        _categories: {
            get() { return this.categories },
            set(val) { this.$emit("setCategories", val) }
        }
    },
    methods: {
        emitPesquisar() {
            this.$emit('pesquisar');
        }
    }
}
</script>